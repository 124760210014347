import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import NewsItem from "./newsItem";

import { frontNews, newsWrapper } from "../../css/frontnews.module.css";

const getNewsItems = graphql`
  query GetNews {
    allStrapiBlog(sort: { order: DESC, fields: created_at }, limit: 3) {
      edges {
        node {
          blogTitle
          created_at
          id
          slug
          mainImage {
            childImageSharp {
              gatsbyImageData(width: 319, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

const FrontNews = () => {
  const data = useStaticQuery(getNewsItems);

  return (
    <article className={newsWrapper}>
      <h2>Latest News</h2>
      <div className={frontNews}>
        {data.allStrapiBlog.edges.map((item) => (
          <NewsItem item={item.node} key={item.node.id} />
        ))}
      </div>
    </article>
  );
};

export default FrontNews;
