import React from "react";
import { Link } from "gatsby";

import {
  locationMap,
  locationAddress,
  addressWrapper,
  locationWrapper,
} from "../../css/location.module.css";

const SiteLocation = ({ location, title }) => {
  const { road, town, city, postCode } = location;

  return (
    <article className={locationWrapper}>
      <div className={addressWrapper}>
        <div className={locationAddress}>
          <h3>Find us</h3>
          <ul>
            <li>
              {title}, {road}
            </li>
            <li>
              {town}, {city}
            </li>
            <li>{postCode}</li>
          </ul>
          <Link to="https://www.google.com/maps?ll=53.958121,-1.531433&z=15&t=m&hl=en&gl=GB&mapclient=embed&cid=6494287264984444771">
            Open in Google Maps
          </Link>
        </div>
      </div>

      <div className={locationMap}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2347.5148849576753!2d-1.5336214!3d53.9581239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795116536b5d87%3A0x5a2057dbfabdaf63!2sPannal%20Village%20Hall!5e0!3m2!1sen!2suk!4v1592567024870!5m2!1sen!2suk"
          id="map01"
          title="Pannal Village Hall location"
        ></iframe>
      </div>
    </article>
  );
};

export default SiteLocation;
