import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { newsLink, imageBox, newsCard } from "../../css/newsitem.module.css";

const NewsItem = ({ item }) => {
  return (
    <div className={newsCard}>
      <div className={imageBox}>
        <GatsbyImage
          image={item.mainImage.childImageSharp.gatsbyImageData}
          alt={item.blogTitle}
          imgStyle={{ objectFit: "cover" }}
          style={{ height: "100%" }}
        />
      </div>
      <p>{item.blogTitle}</p>
      <Link to={`news/${item.slug}`} className={newsLink}>
        Read more
      </Link>
    </div>
  );
};

export default NewsItem;
