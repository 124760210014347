import React from "react";
import { Link } from "gatsby";

import { bookingDirections } from "../css/booking-directions.module.css";

const Banner = () => {
  return (
    <section className={bookingDirections}>
      <h2>Making a Booking</h2>
      <p>
        Need a room? Go to <Link to="/calendar">our calendar</Link>, find your
        space, then go to <Link to="/contact">bookings</Link> to tell us your
        needs.
      </p>
    </section>
  );
};

export default Banner;
