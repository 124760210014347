import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import MainHero from "../components/mainHero";
import Banner from "../components/banner";
import BookingDirections from "../components/bookingDirections";
import FrontNews from "../components/news/newsFront";
import SiteLocation from "../components/home/location";

const getSiteDetails = graphql`
  query IndexQuery {
    file(relativePath: { eq: "PVHMain.jpeg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    site {
      siteMetadata {
        title
        description
        location {
          road
          town
          city
          postCode
        }
      }
    }
    strapiHero {
      heroImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;

const Index = () => {
  const data = useStaticQuery(getSiteDetails);

  const {
    site: {
      siteMetadata: { title, description, location },
    },
  } = data;

  return (
    <>
      <Helmet>
        <title>Home | Pannal Village Hall</title>
      </Helmet>
      <MainHero>
        <GatsbyImage
          image={data.strapiHero.heroImage.childImageSharp.gatsbyImageData}
          alt={title}
        />
        <Banner title={`Welcome to ${title}`} description={description} />
      </MainHero>
      <BookingDirections />
      <FrontNews />
      <SiteLocation location={location} title={title} />
    </>
  );
};

//strapi frontend login username pvh and password pannalpass

export default Index;
